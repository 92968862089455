





































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PS2GameService from "@/services/PS2GameService";
import { bus } from "@/main";

@Component
export default class AssignGame extends Vue {
  @Prop() upc: any;
  @Prop() ghUpc: any;
  @Prop() dialogVisible: any;

  @Watch("dialogVisible")
  onPropertyChanged(value: any) {
    if (value?.value) {
      this.getGames();
      this.search = null;
    }
  }

  public search: any = null;
  public games: any = [];
  public headers: any = [
    { text: "Title", align: "left", value: "title" },
    { text: "Release Date", value: "released" },
    { text: "Developer", value: "developer" },
    { text: "Publisher", value: "publisher" },
    { text: "Region", value: "region" },
    { text: "Owned", value: "owned" },
    { text: "UPC", value: "upc" },
    { text: "GH UPC", value: "gh_upc" },
    { text: "Rating", value: "rating" },
    { text: "", value: "actions" },
  ];

  created() {
    this.getGames();
  }

  async getGames() {
    PS2GameService.getAllPS2Games().then((resp) => (this.games = resp));
  }

  async assign(game) {
    if (this.upc || this.ghUpc) {
      if (this.upc) {
        game.upc = this.upc;
      }
      if (this.ghUpc) {
        game.gh_upc = this.ghUpc;
      }
      const accessToken = await this.$auth.getTokenSilently();
      PS2GameService.update(game.id, game, accessToken)
        .then(() => {
          this.$emit("close-dialog");
        })
        .catch(() => {
          bus.$emit("messageBar", "Error: Couldn't assign game");
        });
    } else {
      bus.$emit("messageBar", "Error: No upc defined");
    }
  }
}
