


































































































































































































































import { Component, Vue } from "vue-property-decorator";
import PS2Service from "@/services/PS2Service";
import EnlargeableImage from "@/components/EnlargeableImage.vue";
import Game from "@/components/Game.vue";
import AssignGame from "@/components/AssignGame.vue";
import Gallery from "@/components/Gallery.vue";
import { bus } from "@/main";
const STATIC_URL = process.env.VUE_APP_STATIC_URL || "/static/";

@Component({
  components: {
    EnlargeableImage,
    Game,
    AssignGame,
    Gallery,
  },
})
export default class PS2 extends Vue {
  public gameDialog = false;
  public assignDialog = false;
  public search: any = null;
  private games: any = [];
  public headers: any = [
    { text: "Title", align: "left", value: "title" },
    { text: "UPC", value: "upc" },
    { text: "Label", value: "greatest_hits" },
    { text: "Price", value: "price", show: this.$auth.isAuthenticated },
    { text: "Quantity", value: "quantity" },
    { text: "Updated Date", value: "revision_date" },
    { text: "Cover", value: "cover" },
    { text: "", value: "actions" },
  ].filter(h => h.show === undefined || h.show);
  public assignUpc: any = null;
  public assignGhUpc: any = null;
  public gameId = 0;

  public galleryMode = false;

  public filterLetters: string[] = [];
  private filterLetter: string | null = null;
  public page = 1;
  public sortBy = 'title';
  public sortDesc = false;
  public select = { text: 'All', val: 0 };
  public selectItems = [
          { text: 'All', val: 0 },
          { text: 'Duplicates', val: 1 },
        ];

  get hasAddGamePermission() {
    return this.$store.getters.getUserPermissions.includes("add:game");
  }

  get hasEditGamePermission() {
    return this.$store.getters.getUserPermissions.includes("edit:game");
  }

  get hasDelGamePermission() {
    return this.$store.getters.getUserPermissions.includes("del:game");
  }

  get filteredGames() {
    return this.filterLetter
      ? this.games.filter(
          (g) => g.title?.toLocaleLowerCase().substr(0, 1) == this.filterLetter
        )
      : this.games;
  }

  created() {
    this.getGames();
  }

  async getGames() {
    if (!this.select.val) {
      PS2Service.getAllPS2().then((resp) => {
        this.games = resp;
        const filterLetterSet = new Set<string>();
        this.games.forEach((g) => {
          g["src"] = this.getCoverPath(g);
          g["thumbnail"] = this.getThumbPath(g);
          g["w"] = 2159;
          g["h"] = 1438;
          g["game"] = g;
          filterLetterSet.add(g.title?.toLocaleLowerCase().substr(0, 1));
        }, this);
        this.filterLetters = [...filterLetterSet].sort();
      });
    } else if (this.select.val === 1) {
      PS2Service.getDuplicates().then((resp) => {
        this.games = resp.data;
        const filterLetterSet = new Set<string>();
        this.games.forEach((g) => {
          g["src"] = this.getCoverPath(g);
          g["thumbnail"] = this.getThumbPath(g);
          g["w"] = 2159;
          g["h"] = 1438;
          g["game"] = g;
          filterLetterSet.add(g.title?.toLocaleLowerCase().substr(0, 1));
        }, this);
        this.filterLetters = [...filterLetterSet].sort();
      });
    }
  }

  getThumbPath(game) {
    const path = game.upc.length < 12 ? game.identifier_2 : game.upc;
    return STATIC_URL + "ps2/" + path + "/thumb.png";
  }

  getCoverPath(game) {
    const path = game.upc.length < 12 ? game.identifier_2 : game.upc;
    return STATIC_URL + "ps2/" + path + "/cover.jpg";
  }

  getDisplayUPC(game) {
    return game.upc.length < 12
      ? game.upc + " (" + game.identifier_2 + ")"
      : game.upc;
  }

  getAltImage(event) {
    event.target.src = STATIC_URL + "ps2/ps2.jpg";
  }

  async remove(id, title) {
    this.$confirm({
      message: `Are you you want to delete ${title}?`,
      button: {
        no: "No",
        yes: "Yes",
      },
      callback: async (confirm) => {
        if (confirm) {
          const accessToken = await this.$auth.getTokenSilently();
          PS2Service.delete(id, accessToken).then(() => this.getGames());
        }
      },
    });
  }

  closeGameDialog(args: any[]) {
    this.gameDialog = false;
    this.gameId = 0;
    this.getGames();
    if (args && args.length === 2) {
      this.assign(args[0], args[1]);
    }
  }

  closeAssign() {
    bus.$emit("messageBar", "Game assigned successfully");
    this.assignDialog = false;
    this.getGames();
  }

  assign(upc, gh) {
    if (gh) {
      this.assignGhUpc = upc;
    } else {
      this.assignUpc = upc;
    }
    this.assignDialog = true;
  }

  editGame(id) {
    this.gameId = id;
    this.gameDialog = true;
  }

  toggleGalleryMode() {
    this.galleryMode = !this.galleryMode;
  }

  setFilterLetter(char: string | null) {
    this.filterLetter = char;
    this.page = 1;
  }

  getFilteredLetterDisplay(char: string | null) {
    if (char == null) {
      return this.filterLetter ? "All" : "[All]";
    }
    if (this.filterLetter?.toLocaleLowerCase() === char.toLocaleLowerCase()) {
      return "[" + char.toLocaleUpperCase() + "]";
    } else {
      return char.toLocaleUpperCase();
    }
  }

  filterByStartingLetter(value: any, search: string | null) {
    return (
      value != null &&
      typeof value === "string" &&
      (this.filterLetter == null ||
        value.toLocaleLowerCase().toString().indexOf(this.filterLetter) ===
          0) &&
      (search == null ||
        value.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1)
    );
  }
}
