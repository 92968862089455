




import { Component, Prop, Vue } from "vue-property-decorator";
import { StreamBarcodeReader } from "vue-barcode-reader";

@Component({
  components: {
    StreamBarcodeReader,
  },
})
export default class Scanner extends Vue {
  @Prop() shown;

  onDecode(result: string) {
    console.log("Scanned UPC: " + result);
    if (this.validUPC(result)) {
      this.$emit("close-scanner-dialog", this.getValidUPC(result));
    }
  }

  validUPC(upc: string) {
    if (upc.length === 12) {
      return true;
    }

    if (upc.length < 12 || upc.length > 13) {
      return false;
    }

    const array = upc.slice(0, -1).split('').reverse();

    let total = 0;
    let i = 1;
    array.forEach(digit => {
        const number = parseInt(digit);
        if (i % 2 === 0) {
            total = total + number;
        }
        else
        {
            total = total + (number * 3);
        }
        i++;
    });

    return upc.endsWith(((Math.ceil(total / 10) * 10) - total).toString());
  }

  getValidUPC(upc: string) {
    if (upc.length === 12) {
      return upc;
    } else {
      return upc.slice(0, -1);
    }
  }

  onLoaded() {
    console.log("Scanner Loaded");
  }
}
