












































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PS2Service from "@/services/PS2Service";
import Scanner from "@/components/Scanner.vue";

@Component({
  components: {
    Scanner,
  },
})
export default class Game extends Vue {
  @Prop() id: number;

  @Watch("id")
  onPropertyChanged(value: any) {
    this.getGame(value);
  }

  public scannerDialog = false;

  valid = true;
  upcRules = [
    (v) => !!v || "UPC is required",
    (v) => (v || "").length <= 12 || "UPC must be less than 12 characters",
  ];
  identifier_1_rules = [
    (v) =>
      (v || "").length <= 255 ||
      "Identifier_1 must be less than 255 characters",
  ];
  caseRules = [(v) => !!v || "Case Condition is required"];
  discRules = [(v) => !!v || "Disc Condition is required"];
  notesRules = [
    (v) => (v || "").length <= 255 || "Notes must be less than 255 characters",
  ];
  typeRules = [(v) => !!v || "Game Type is required"];

  title: any = null;
  upc: any = null;
  identifier_1: any = null;
  inc_manual = true;
  greatest_hits = false;
  backed_up = false;
  case_condition: any = null;
  disc_condition: any = null;
  notes: any = null;
  buy_price: any = null;
  game_type = 1;
  quantity = 1;

  originalData = {
    title: null,
    upc: null,
    identifier_1: null,
    inc_manual: true,
    greatest_hits: false,
    backed_up: false,
    case_condition: null,
    disc_condition: null,
    notes: null,
    buy_price: null,
    game_type: 1,
    quantity: 1,
  };

  gameTypes = [
    { name: "Game", val: 1 },
    { name: "Demo", val: 2 },
    { name: "Compilation", val: 3 },
  ];

  created() {
    this.getGame(this.id);
  }

  async getGame(id: number) {
    if (id > 0) {
      PS2Service.getPS2(id).then((resp) => Object.assign(this.$data, resp));
    } else {
      Object.assign(this.$data, this.originalData)
    }
  }

  closeScannerDialog(upc: any) {
    this.scannerDialog = false;
    this.upc = upc;
  }

  async processForm(event: any, assign?: boolean) {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      const accessToken = await this.$auth.getTokenSilently();
      const data = {
        upc: this.upc,
        identifier_1: this.identifier_1,
        inc_manual: this.inc_manual,
        greatest_hits: this.greatest_hits,
        backed_up: this.backed_up,
        case_condition: this.case_condition,
        disc_condition: this.disc_condition,
        notes: this.notes,
        buy_price: this.buy_price,
        game_type: this.game_type,
        quantity: this.quantity
      };
      if (this.id) {
        PS2Service.update(this.id, data, accessToken).then(() => {
          if (assign) {
            this.$emit("close-dialog", [this.upc, this.greatest_hits]);
          } else {
            this.$emit("close-dialog");
          }
          Object.assign(this.$data, this.originalData);
        });
      } else {
        PS2Service.create(data, accessToken).then(() => {
          if (assign) {
            this.$emit("close-dialog", [this.upc, this.greatest_hits]);
          } else {
            this.$emit("close-dialog");
          }
          Object.assign(this.$data, this.originalData);
        });
      }
    }
  }
}
