



































import { Component, Prop, Vue } from "vue-property-decorator";
import VuePictureSwipe from "vue-picture-swipe";

@Component
export default class Gallery extends Vue {
  @Prop() items: any;
  public slideshow = false;
  private timer: any;

  public sorts = [
    { text: "Title Asc", value: "+title" },
    { text: "Title Desc", value: "-title" },
    { text: "Revision Date Asc", value: "+revision_date" },
    { text: "Revision Date Desc", value: "-revision_date" },
  ];

  created() {
    this.slideshow =
      (this.$cookie.get("gallery-slideshow") ?? "true") === "true";
    if (this.slideshow) {
      this.startTimer();
    }
  }

  beforeDestroy() {
    this.stopTimer();
  }

  close() {
    clearInterval(this.timer);
    this.$emit("close-gallery");
  }

  startTimer() {
    this.timer = setInterval(this.nextItem, 10000);
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  slideshowToggle() {
    this.slideshow = !this.slideshow;
    if (this.slideshow) {
      this.startTimer();
    } else {
      this.stopTimer();
    }
  }

  nextItem() {
    const vuePictureSwipe = this.$refs.pictureSwipe as VuePictureSwipe;
    vuePictureSwipe.pswp?.next();
  }

  changeSort(sort) {
    const direction = sort?.value.substr(0, 1) === "-" ? "desc" : "asc";
    const property = sort?.value.substring(1);
    if (direction && property) {
      const vuePictureSwipe = this.$refs.pictureSwipe as VuePictureSwipe;
      vuePictureSwipe.items = this.lodash.orderBy(
        vuePictureSwipe.items,
        (item) => item.game[property],
        [direction]
      );
    }
  }
}
