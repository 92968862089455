import BaseService from "./BaseService";

class PS2GameService extends BaseService {
  constructor() {
    super("ps2games");
  }

  async getPS2GamesList(page?: number) {
    return this.getList(page).then((resp) => {
      return resp;
    });
  }

  async getAllPS2Games() {
    return this.getAll().then((resp) => {
      return resp;
    });
  }

  async getAllPS2GamesCount() {
    return this.getCount().then((resp) => {
      return resp;
    });
  }
}

export default new PS2GameService();
