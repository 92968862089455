var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[(_vm.galleryMode)?_c('Gallery',{attrs:{"items":_vm.filteredGames},on:{"close-gallery":_vm.toggleGalleryMode}}):_c('v-card',{staticStyle:{"width":"75%"}},[_c('v-card-title',[_vm._v(" Games "),(_vm.hasAddGamePermission)?_c('v-dialog',{attrs:{"persistent":"","transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var click = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('md-button',_vm._g(_vm._b({staticClass:"md-icon-button md-primary",attrs:{"id":"show-modal"}},'md-button',attrs,false),Object.assign({}, click, tooltip)),[_c('md-icon',{attrs:{"md-theme":_vm.$vuetify.theme.dark ? 'default-dark' : 'default'}},[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v("Add Game")])])]}}],null,false,2149770494),model:{value:(_vm.gameDialog),callback:function ($$v) {_vm.gameDialog=$$v},expression:"gameDialog"}},[[_c('md-button',{staticClass:"md-icon-button md-primary close-btn",on:{"click":function($event){_vm.gameDialog = false;
                _vm.gameId = -1;}}},[_c('md-icon',{attrs:{"md-theme":_vm.$vuetify.theme.dark ? 'default-dark' : 'default'}},[_vm._v("cancel_presentation")])],1),_c('Game',{class:[_vm.$vuetify.theme.dark ? 'dark-dialog' : 'light-dialog'],attrs:{"id":_vm.gameId,"dialogVisible":_vm.gameDialog},on:{"close-dialog":_vm.closeGameDialog}})]],2):_vm._e(),_c('div',{staticClass:"flex-grow-1"}),_c('v-select',{attrs:{"items":_vm.selectItems,"item-text":"text","item-value":"val","label":"Filter","return-object":"","single-line":""},on:{"change":_vm.getGames},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('md-button',_vm._g({staticClass:"md-icon-button md-primary gallery-btn",on:{"click":_vm.toggleGalleryMode}},on),[_c('md-icon',{attrs:{"md-theme":_vm.$vuetify.theme.dark ? 'default-dark' : 'default'}},[_vm._v("photo_library")])],1)]}}])},[_c('span',[_vm._v("Gallery Mode")])]),_c('v-text-field',{attrs:{"append-icon":"fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"filterLetters"},[_c('a',{on:{"click":function($event){return _vm.setFilterLetter(null)}}},[_vm._v(_vm._s(_vm.getFilteredLetterDisplay(null)))]),_vm._l((_vm.filterLetters),function(char){return _c('a',{key:char,on:{"click":function($event){return _vm.setFilterLetter(char)}}},[_vm._v(_vm._s(_vm.getFilteredLetterDisplay(char)))])})],2),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredGames,"search":_vm.search,"custom-filter":_vm.filterByStartingLetter,"page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
                var item = ref.item;
return [_c('span',[_c('a',{on:{"click":function($event){return _vm.editGame(item.id)}}},[_vm._v(_vm._s(item.title))])])]}},{key:"item.upc",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDisplayUPC(item)))])]}},{key:"item.greatest_hits",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.greatest_hits ? "Greatest Hits" : "Black Label"))])]}},{key:"item.price",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currencyFormat(item.price)))])]}},{key:"item.revision_date",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.revision_date)))])]}},{key:"item.cover",fn:function(ref){
                var item = ref.item;
return [_c('span',[_c('enlargeable-image',{attrs:{"src":_vm.getThumbPath(item),"src_large":_vm.getCoverPath(item),"error":_vm.getAltImage}})],1)]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"actions"},[(_vm.hasEditGamePermission)?_c('md-button',{staticClass:"md-icon-button md-primary",on:{"click":function($event){return _vm.editGame(item.id)}}},[_c('md-icon',{attrs:{"md-theme":_vm.$vuetify.theme.dark ? 'default-dark' : 'default'}},[_vm._v("edit")])],1):_vm._e(),(_vm.hasEditGamePermission)?_c('md-button',{staticClass:"md-icon-button md-primary",on:{"click":function($event){return _vm.assign(item.upc, item.greatest_hits)}}},[_c('md-icon',{attrs:{"md-theme":_vm.$vuetify.theme.dark ? 'default-dark' : 'default'}},[_vm._v("assignment")])],1):_vm._e(),(_vm.hasDelGamePermission)?_c('md-button',{staticClass:"md-icon-button md-primary",on:{"click":function($event){return _vm.remove(item.id, item.title)}}},[_c('md-icon',{attrs:{"md-theme":_vm.$vuetify.theme.dark ? 'default-dark' : 'default'}},[_vm._v("delete")])],1):_vm._e()],1)]}}],null,true)}),_c('div',{staticClass:"filterLetters"},[_c('a',{on:{"click":function($event){return _vm.setFilterLetter(null)}}},[_vm._v(_vm._s(_vm.getFilteredLetterDisplay(null)))]),_vm._l((_vm.filterLetters),function(char){return _c('a',{key:char,on:{"click":function($event){return _vm.setFilterLetter(char)}}},[_vm._v(_vm._s(_vm.getFilteredLetterDisplay(char)))])})],2)],1)],1),_c('v-dialog',{attrs:{"persistent":"","transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"default",fn:function(assignDialog){return [_c('md-button',{staticClass:"md-icon-button md-primary close-btn",on:{"click":function($event){assignDialog.value = false}}},[_c('md-icon',{attrs:{"md-theme":_vm.$vuetify.theme.dark ? 'default-dark' : 'default'}},[_vm._v("cancel_presentation")])],1),_c('AssignGame',{class:[_vm.$vuetify.theme.dark ? 'dark-dialog' : 'light-dialog'],attrs:{"dialogVisible":assignDialog,"upc":_vm.assignUpc,"ghUpc":_vm.assignGhUpc},on:{"close-dialog":_vm.closeAssign}})]}}]),model:{value:(_vm.assignDialog),callback:function ($$v) {_vm.assignDialog=$$v},expression:"assignDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }